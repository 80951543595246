/* You can add global styles to this file, and also import other style files */
:root{
    --sama-bleu: #01224b;
    --sama-bleu-e:#0267E3;
    --sama-rouge: #d61a0e;
    --sama-bleu-d: #1F3E63;
}

*{
    margin: 0;
    padding: 0;
}
body {
    font-size: 18px;
}

h1{
    margin-top: 20px;
}